.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}
.progress-control {
    z-index: 50;
    opacity: 1;
    transition: all ease 0.5s;
    transform: unset;
}
.progress-control .hide {
    transform: translateY(20px);
    z-index: 10;
    opacity: 0;
    transition: all ease 0.5s;
}
.video-react {
    background-color: transparent !important;
}
.video-react .video-react-big-play-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    line-height: 60px;
    border: none;
}
.hide-player {
    display: none;
}
.video-react .video-react-control-bar {
    flex-wrap: wrap;
}

.video-react .video-react-progress-control {
    align-items: flex-start;
    width: 100%;
}
.video-react .video-react-control {
    height: auto;
}
.video-react .video-react-progress-holder {
    height: 5px;
}
.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-progress-holder .video-react-load-progress,
.video-react .video-react-progress-holder .video-react-tooltip-progress-bar,
.video-react .video-react-progress-holder .video-react-load-progress div {
    height: 5px;
}
.video-react .video-react-play-progress:before {
    font-size: 17px;
    color: #15bf81;
}
.video-react .video-react-progress-holder .video-react-play-progress {
    background: #0fa76f;
}
.video-react .video-react-slider:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.video-react .video-react-control-bar {
    height: 40px;
}
.fullscreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#video-player {
    transition: ease 0.4s;
    overflow: hidden;
}
#video-player .button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 34px;
    width: 100%;
    position: absolute;
    bottom: 30px;
    font-family: gordita_medium;
    font-size: 14px;
    transition: all ease 0.5s;
    opacity: 1;
}
#video-player .button-container.hide {
    opacity: 0;
    transition: all ease 0.2s;
}
#video-player .player-overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(100% - 70px);
}
#video-player .player-overlay.upper {
    z-index: 50;
}
#video-player .top-controlls {
    position: absolute;
    left: 4%;
    top: 6%;
    display: flex;
    min-width: 92%;
    align-items: center;
    justify-content: space-between;
    z-index: 51;
    transition: all 0.3s ease;
    opacity: 1;
}
#video-player .top-content {
    display: flex;
    align-items: center;
    cursor: pointer;
}
#video-player .top-content.top-right {
    margin-right: 20px;
}
#video-player .top-content.top-right:last-child {
    margin-right: unset;
}
#video-player .top-content.top-right:hover div.icon-selected {
    transform: scale(1.1);
    transition: ease 0.4s;
}
#video-player .top-content.top-right:hover div.icon {
    transform: scale(1.1);
    transition: ease 0.4s;
}
#video-player .top-controlls.hide {
    top: 5%;

    z-index: 46;

    opacity: 0;
    transition: all 0.3s ease;
}
#video-player .top-right-controls {
    display: flex;
    align-items: center;
}
#video-player .control-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}
#video-player .top-right-controls .control-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
}
#video-player .top-right-controls .control-item:last-child {
    margin-right: 0;
}
#video-player .top-right-controls .control-item:hover div.icon-selected {
    transform: scale(1.1);
    transition: ease 0.4s;
}
#video-player .top-right-controls .control-item:hover div.icon {
    transform: scale(1.1);
    transition: ease 0.4s;
}
#video-player .top-title {
    font-size: 13px;
    font-family: "gordita_medium";
    margin-right: 10px;
    padding-top: 3px;
}
#video-player .video-react .video-react-play-progress:before {
    color: #15bf81;
    font-size: 20px;
    line-height: 16px;
}
#video-player .video-react .video-react-mouse-display:after,
#video-player .video-react .video-react-play-progress:after,
#video-player .video-react .video-react-time-tooltip {
    font-family: "gordita_regular";
}
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-play-progress {
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
}
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-play-progress,
#video-player .video-react .video-react-progress-holder,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-load-progress,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-tooltip-progress-bar,
#video-player
    .video-react
    .video-react-progress-holder
    .video-react-load-progress
    div {
    height: 3px;
    border-radius: 15px;
}
#video-player .video-react .video-react-load-progress {
    background: #f5f5f5;
}

#video-player
    .video-react
    .video-react-big-play-button.video-react-big-play-button-center {
    top: 50%;
    left: 50%;
    margin-top: unset;
    margin-left: unset;
    transform: translate(-50%, -50%);
}
#video-player.hidden .video-react .video-react-control-bar {
    background: rgba(0, 0, 0, 0) !important;
    transition: all ease 0.5s;
    opacity: 0;
}

#video-player .video-react .video-react-control-bar {
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    transition: all ease 0.5s;
    opacity: 1;
}
#video-player .video-react .video-react-control-bar .bottom {
}
#video-player .video-react .video-react-control {
    padding: 0 30px;
    height: 63px;
    position: absolute;
    bottom: 0;
}
@media only screen and (max-width: 640px) {
    #video-player .top-title {
        display: none;
    }
    #video-player .top-right-controls .control-item {
        margin-right: 15px;
    }
    #video-player .top-right-controls .control-item:last-child {
        margin-right: unset;
    }
    #video-player .top-content.top-right {
        margin-right: 15px;
    }
    #video-player .top-content.top-right:last-child {
        margin-right: unset;
    }
    #video-player .top-controlls {
        top: 10%;
    }
    #video-player .button-container {
        bottom: 13px;
        font-size: 13px;
    }
    #video-player .video-react .video-react-control {
        padding: 0 30px;
        height: 45px;
        position: absolute;
        bottom: 0;
    }
    .video-react .video-react-play-progress:before {
        font-size: 17px;
        color: #15bf81;
    }
    #video-player .video-react .video-react-play-progress:before {
        color: #15bf81;
        font-size: 17px;
        line-height: 15px;
    }
}

@media only screen and (max-width: 480px) {
    #video-player .button-container {
        padding: 0 12px;
        bottom: 12px;
    }
    #video-player .video-react .video-react-control {
        padding: 0 10px;
        height: 40px;
    }
    .video-react .video-react-play-progress:before {
        font-size: 15px;
        color: #15bf81;
    }
    #video-player .video-react .video-react-play-progress:before {
        color: #15bf81;
        font-size: 15px;
        line-height: 13px;
    }
}
#video-player .video-react .slide-left {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}
.play-pause {
    display: flex;
    align-items: center;
    justify-content: center;
}
.play-pause label {
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 25px;
    cursor: pointer;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    will-change: border-width;
    border-style: double;
    border-width: 0px 0 0px 34px;
}
.play-pause input[type="checkbox"] {
    visibility: hidden;
}
.play-pause input[type="checkbox"]:checked + label {
    border-style: solid;
    border-width: 22px 0 22px 36px;
}

#video-player .v-play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 25px;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: solid;
    border-width: 25px 0 25px 39px;
}
#video-player .v-play-button.playing {
    border-style: double;
    border-width: 0px 0 0px 41px;
}
#video-player .v-play-button:hover {
    border-color: transparent transparent transparent #fff;
}
